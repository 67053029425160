import React, {useState, useEffect, useRef} from 'react';
import '../assets/styles/Menu.css';
import logo from '../assets/images/AvaxGuardianLogo.png';
import xIcon from '../assets/images/icons/x-icon.png';
import telegramIcon from '../assets/images/icons/telegram-icon.png';
import avalancheIcon from '../assets/images/icons/avalanche-black-icon.png';
import joeIcon from '../assets/images/icons/Joe-icon.png';
import dexscreenerIcon from '../assets/images/icons/dexscreener-icon.png';
import hamburgerIcon from '../assets/images/icons/Hamburger_icon.svg.png';
let xLink = "https://twitter.com/AvaxGuardian"
let joeLink = "https://traderjoexyz.com/avalanche/trade"
let snowtraceLink = "https://snowtrace.io/"
let telegramLink = "https://t.me/+GX0RzUdRoP4xNWQ0"
let dexscreenerLink = "https://dexscreener.com/avalanche"
// let tokenomicsLink = ""


const Menu = () => {

    const [open, setOpen] = useState(false);

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e)=>{
            if(!menuRef.current.contains(e.target)){
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handler)

        return () =>{
            document.removeEventListener("mousedown", handler)
        }
    });

    const buttons = [
        { id: 1, name: 'Snowtrace', icon: avalancheIcon, url: snowtraceLink },
        { id: 2, name: 'X Profile', icon: xIcon, url: xLink },
        { id: 3, name: 'Telegram', icon: telegramIcon, url: telegramLink },
        { id: 4, name: 'Traderjoe', icon: joeIcon, url: joeLink },
      ];

      const handleClick = (url) => {
        window.open(url, '_blank');
      };

      const handleLinkHomeClick = (event) => {
        event.preventDefault();
        // Autres actions
      };

  return (
    <div className='menu-container' ref={menuRef}>
        <nav className="menu">
        {/* Part 1: Logo */}
        <div className="menu-logo">
            <img src={logo} alt="Logo" />
        </div>

        {/* Part 2: Menu Links */}
        <div className="menu-links">
            <a href="#" onClick={handleLinkHomeClick} className='menu-links-home'>🛡️✨ Home</a>
            {/* <a href={tokenomicsLink}>🛡️🌟 Guardianomics</a> */}
            <a href={dexscreenerLink} target="_blank" rel="noopener noreferrer">🛡️💫 Chart</a>
            {/* <a href={joeLink} target="_blank" rel="noopener noreferrer" className='menu-links-traderjoe'>🛡️🚀 TraderJoe</a> */}
        </div>

        <div className="menu-button-buy">
            <button onClick={() => handleClick(joeLink)} className='buy-token-button'>🚀 Trade</button>
        </div>


        {/* Part 3: Buttons and icon */}
        <div className="menu-actions">
            {buttons.map((button) => (
                <button key={button.id} onClick={() => handleClick(button.url)} className="icon-button">
                <img src={button.icon} alt={button.name} />
                </button>
            ))}
        </div>


        {/* <div className="menu-actions">
            <button key={buttons.id} onClick={() => handleClick(buttons.url)} className="icon-button">
            <img src={avalancheIcon} alt="Snowtrace" />
            </button>
            <button key={buttons.id} onClick={() => handleClick(buttons.url)} className="icon-button">
            <img src={xIcon} alt="XProfileTwitter" />
            </button>
            <button key={buttons.id} onClick={() => handleClick(buttons.url)} className="icon-button">
            <img src={telegramIcon} alt="Telegram" />
            </button>
            <button key={buttons.id} onClick={() => handleClick(buttons.url)} className="icon-button">
            <img src={joeIcon} alt="Traderjoe" />
            </button>
        </div> */}

        {/* Part 4: Responsive menu */}
        <div className="hamburger-menu">
            <button className="menu-trigger icon-button" onClick={()=>{setOpen(!open)}}>
            <img src={hamburgerIcon} alt="Traderjoe" />
            </button>
        </div>

        <div className={`dropdown-menu ${open? 'active' : 'inactive'}`}>
            <ul>
                <DropdownItem img = {dexscreenerIcon} text = {"Dexscreener"} href = {dexscreenerLink}/>
                <DropdownItem img = {avalancheIcon} text = {"Snowtrace"} href = {snowtraceLink}/>
                <DropdownItem img = {xIcon} text = {"X Profile"} href = {xLink}/>
                <DropdownItem img = {telegramIcon} text = {"Telegram"} href = {telegramLink}/>
                <DropdownItem img = {joeIcon} text = {"Traderjoe"} href={joeLink}/>
            </ul>
        </div>
        </nav>
    </div>
  );
};

export default Menu;


function DropdownItem(props) {
    return (
        <li className='dropdown-item'>
            <img src={props.img} alt={props.text}></img>
            <a href={props.href} target="_blank" rel="noopener noreferrer"> {props.text} </a>
        </li>
    );
}