import React from 'react';
import '../assets/styles/footer.css';
import poweredByAvalanche from '../assets/images/PoweredByAvalanche.png';
import poweredByAvalancheArts from '../assets/images/PoweredByAvalancheArtsAndCulture.png';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='sponsor-container'>
                <img src={poweredByAvalanche} alt="poweredByAvalanche"></img>
                <img src={poweredByAvalancheArts} alt="poweredByAvalancheArts"></img>
            </div>
        </div>
    );
}

export default Footer;