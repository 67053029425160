import React, { useState } from 'react';
import '../assets/styles/AnimatedFlexCards.css';

import backgroundCard from '../assets/images/background1-section0.png';

const cardData = [
  {
    id: 'c1',
    title: '🛡️✨ Birth of the Guardian',
    description: "Deep in the icy depths of the mountains, a team of passionate scientists was on a quest to solve the blockchain trilemma, seeking the perfect balance between security, scalability, and decentralization. On one fateful evening, their lab, buzzing with unknown energies, exploded, triggering a catastrophic avalanche. Rising from the eternal snows, the Yeti Avalanche Guardian was born, an indomitable guardian of a new blockchain frontier, born of human ambition and forged by nature's fury.",
    backgroundImage: backgroundCard,
  },
  {
    id: 'c2',
    title: "🎉 AvaxGuardian Funhouse 🚀",
    description: "Step into the AvaxGuardian Funhouse, where the thrill of the Avalanche blockchain meets the whimsy of meme culture. Here, every snowflake tells a story, and each footprint leads to undiscovered treasures. Immerse yourself in a world where the AvaxGuardian plays, and reveals hidden blockchain gems. From engaging challenges to legendary quests, the Funhouse is your gateway to fun, fortune, and the untold secrets of the AvaxGuardian. Join the adventure, share the laughter, and be part of the lore that shapes the future of finance and fun.",
    backgroundImage: backgroundCard,
  },
  {
    id: 'c3',
    title: "🌟 Unleash the Magic 🌈",
    description: "Embark to infuse the Avalanche ecosystem with the enchanting spirit of AvaxGuardian, where humor and sharing becomes our strongest spell. Together, we're scouting for the talented artists within the Avalanche realms, making every pixel a testament to our collective creativity.\nThis quest isn't just for the chosen few, it's a call to arms for every dreamer, and believer. Your art, and your voice have the power to shape this odyssey. Ready to track down the mythical snow beast and etch your mark in the annals of AvaxGuardian lore ? The adventure begins now !",
    backgroundImage: backgroundCard,
  },
  {
    id: 'c4',
    title: "🛡️ Guardianomics: Fairness First 🌟",
    description: "At the heart of AvaxGuardian lies a commitment to fairness and transparency: no allocations, no presale, and no taxes. We're contemplating a whitelist phase exclusively for our most engaged community members. Stay tuned and turn on notifications for our socials to be part of the conversation and ensure you're front and center for all updates. Our aim? A launch as fair as the untouched snow. Our team is passionately focused on nurturing a culture of blockchain knowledge and advancing Avalanche's educational front. Join us in shaping a fair and enlightened future.",
    backgroundImage: backgroundCard,
  },
];

const AnimatedFlexCards = () => {
    const [selectedCard, setSelectedCard] = useState('c1');
  
    const renderDescriptionWithLineBreaks = (description) => {
      return description.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    };
  
    return (
      <div className="wrapper">
        <div className="container">
          {cardData.map((card) => (
            <React.Fragment key={card.id}>
              <input
                type="radio"
                name="slide"
                id={card.id}
                checked={selectedCard === card.id}
                onChange={() => setSelectedCard(card.id)}
              />
              <label htmlFor={card.id} className="card" style={{ backgroundImage: `url(${card.backgroundImage})` }}>
                <div className="row">
                  <div className="icon">{card.id.charAt(1)}</div>
                  <div className="description">
                    <h2>{card.title}</h2>
                    <p>{renderDescriptionWithLineBreaks(card.description)}</p>
                  </div>
                </div>
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

export default AnimatedFlexCards;
