import React from 'react';
import Menu from './components/Menu';
import HomePresentation from './components/Home-presentation';
import Footer from './components/Footer';
import AnimatedFlexCards from './components/AnimatedFlexCards';
import './App.css';


function App() {
  return (
    <div className="App">
      <Menu />
      <HomePresentation />
      <AnimatedFlexCards />
      <Footer />
    </div>
  );
}

export default App;