import React from 'react';
import '../assets/styles/Home-presentation.css';

const HomePresentation = () => {
    return (
        <div className='home-presentation-container'>
        <div className='home-presentation-intro'>
            <h1>Avax Guardian</h1>
            <p>A new cultural coin of the C-Chain, fierce protector of the Avalanche Consensus</p>
        </div>
      </div>
    );
}

export default HomePresentation;